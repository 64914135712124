.modal-content {
  box-shadow: none !important;
}

.confirm {
  width: 114px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 0.5rem !important;
  align-items: center;
  box-shadow: none !important;
  border: none !important;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  color: #fff !important;
  letter-spacing: -0.32px;
  font-size: 16px;
}

.confirm-keyword-stuffing {
  width: 200px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 0.5rem !important;
  align-items: center;
  box-shadow: none !important;
  border: none !important;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  color: #000000 !important;
  letter-spacing: -0.32px;
  font-size: 16px;
}

.row-mb-lg {
  margin-bottom: 20px;
}

.progress-custom {
  height: 6px !important;
  overflow: unset !important;
  width: 72px;
  margin: 0 auto;
  background: #e0e0e0 !important;
}

.progress-custom > .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  height: 6px;
  border-radius: 9px !important;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.text-progress {
  font-weight: normal !important;
  text-align: center;
}

.text-darkorange {
  color: #ee3900;
}

.text-purple {
  color: #951c81;
}

.text-orange {
  color: #ff8d4b;
}

.text-green {
  color: #43b929 !important;
}

.text-blue {
  color: #0064d2;
}

.text-gray {
  color: #bdbdbd;
}

.bar-darkorange {
  background: #ee3900 !important;
}

.bar-orange {
  background: #ff8d4b !important;
}

.bar-green {
  background: #43b929 !important;
}

.bt-clock img {
  width: 22px;
}

.label-title {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #333;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.number-wrapper {
  position: relative;
}

.form-ct {
  height: 42px;
}

.form-ct-bill {
  height: 42px;
  width: 164px;
}

.dropdown-custom.dropdown-toggle.show {
  background-color: #fff !important;
  color: #3c3c3c !important;
}

#inc-button {
  right: 11px;
  top: 5px;
  background-color: #fff;
}

#dec-button {
  right: 11px;
  top: 19px;
  background-color: #fff;
}

.bill-number {
  width: 164px !important;
}

.number-group #inc-button,
.number-group #dec-button {
  right: 45px;
}

.number-group #inc-button {
  top: 5px;
  background-color: #fff;
}

.number-group #dec-button {
  top: 19px;
  background-color: #fff;
}

.spinner-button,
.box-icon-calendar {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  width: 24px;
  text-align: center;
  margin: 0px;
  pointer-events: none;
  height: 18px;
  font-size: 14px;
}

#date-button {
  right: 9px;
  top: 9px;
  background-color: none;
}

.bt-submit-disabled {
  width: 137px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #e0e0e0;
  border: none;
  color: #bdbdbd;
}

.bt-submit {
  width: 137px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  color: #3c3c3c;
}

/*slider*/

.slidecontainer {
  width: 70px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.slidecontainer span {
  display: inline-block;
  margin-top: -20px;
}

.slidecontainer span.ic-false {
  background-image: url('../../../public/Images/slider/icon-false.png');
  width: 20px;
  height: 20px;
  background-size: auto;
  background-repeat: no-repeat;
  float: left;
}

.slidecontainer span.ic-true {
  background-image: url('../../../public/Images/slider/icon-true.png');
  width: 20px;
  height: 20px;
  background-size: auto;
  background-repeat: no-repeat;
  float: right;
  margin-right: -4px;
}

.sliderd {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  outline: none;
  height: 8px;
  /*-webkit-transition: .2s;*/
  /*transition: opacity .2s;*/
  /*background-color: red;*/
  cursor: pointer;
}

.slider2::-webkit-slider-thumb,
.slider1::-webkit-slider-thumb,
.slider3::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  /*cursor: pointer;*/
  border-radius: 50%;
  width: 28px;
  height: 28px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.1), 2px -1px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px #fff;
  background-color: #e0e0e0;
}

.slider2::-moz-range-thumb,
.slider1::-moz-range-thumb,
.slider3::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  /*cursor: pointer;*/
  border-radius: 50%;
  width: 28px;
  height: 28px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.1), 2px -1px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px #fff;
  background-color: #e0e0e0;
}

.sliderd.slider1 {
  border-radius: 60px;
  background-color: #ee3900;
  height: 8px;
}

.sliderd.slider3 {
  border-radius: 60px;
  background-color: #43b929;
  height: 8px;
}

.slider3 ~ .ic-false {
  display: none !important;
}

.slider1 ~ .ic-true {
  display: none !important;
}

.slider-disabled > .ic-false {
  background-image: url('../../../public/Images/slider/icon-false-gray.png') !important;
}

.slider-disabled > .ic-true {
  background-image: url('../../../public/Images/slider/icon-true-gray.png') !important;
}

.slider-disabled > .slider2::-webkit-slider-thumb {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.1), 2px -1px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px #bdbdbd;
  background-color: #e0e0e0;
}

.slider-disabled > .slider2::-moz-range-thumb {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  box-shadow: -1px 2px 8px rgba(0, 0, 0, 0.1), 2px -1px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px #bdbdbd;
  background-color: #e0e0e0;
}

.slider-disabled,
.slider-disabled > input.sliderd {
  cursor: default !important;
}

.smart_order_icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.smart_order_icon img {
  height: 30px;
}

table.table-lists tr:hover .smart_order_icon {
  z-index: -1;
}

.auto-save-display {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjusted width for a smaller switch */
  height: 20px; /* Adjusted height for a thinner slider */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3c3c3c; /* Default grey background */
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: -3px;
  bottom: -3px;
  background-color: #ccc; /* Default color of the circle */
  transition: 0.4s;
  border-radius: 50%;
}

/* Inactive state (yellow) */
.switch.inactive .slider {
  background-color: rgba(255, 228, 38, 0.7); /* Yellow background */
}

.switch.inactive .slider:before {
  background-color: rgba(255, 228, 38, 0.7); /* Yellow color of the circle */
}

/* Active state (green) */
input:checked + .slider {
  background-color: #a1dc94; /* Green background */
}

input:checked + .slider:before {
  transform: translateX(14px);
  background-color: #a1dc94; /* Green color of the circle */
}

/* Optional: focus outline for accessibility */
input:focus + .slider {
  box-shadow: 0 0 1px #a1dc94;
}

.sidebar {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  border-bottom: solid 1px #eee;
  padding: 0px 8px 8px 0px;
  cursor: pointer;
}

.loader {
  width: 14px;
  height: 14px;
  border: 2px solid #999;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
