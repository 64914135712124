.loginPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
  margin-top: -100px;
}

.LoginformContainer {
  max-width: 700px;
  padding: 50px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.LoginformContainer a {
  color: blue !important;
  text-decoration: underline !important;
}

.LoginformContainer a:hover {
  color: blue !important;
  text-decoration: underline !important;
}

.formTitle {
  font-size: 32px;
  margin: 20px 0;
}

.formSubTitle {
  color: #777;
  font-size: 16px;
  margin-bottom: 30px;
}

.formLogo {
  position: relative;
  width: 100px;
  height: auto;
}

.inputContainer {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.inputContainer label {
  font-weight: bold;
  margin-bottom: 8px;
}

.inputContainer input {
  max-width: 450px;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.passwordWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.togglePassword {
  position: absolute !important;
  right: 10px;
}

.loginButton {
  width: 100%;
  max-width: 450px;
  padding: 15px;
  background-color: #ffe426;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  color: #3c3c3c;
  font-family: Poppins, sans-serif !important;
  cursor: pointer;
  margin: 10px auto;
}

.loginButton:hover {
  background-color: #fddf00;
}

.loginButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: #888;
  font-size: 14px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0 10px;
}

.alertError {
  margin-top: 15px;
  text-align: left;
}

.sideImage {
  max-width: 800px;
  height: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .LoginformContainer {
    padding: 20px;
  }

  .formLogo {
    width: 70px;
  }
}

@media (max-width: 1024px) {
  .sideImage {
    display: none;
  }
}
