.bt-download-bill {
  background-image: url('../../../public/Images/telecharger.png');
}

.txt-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: -0.4px;
  color: #3c3c3c;
}

.txt-title > span.txt-normal {
  font-size: 14px;
  color: #828282;
}

.box-list-header {
  display: inline-flex;
  width: 100%;
  justify-items: center;
  align-items: center;
}

.box-list-header-c {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.box-list-header,
.box-list-header-c > .box-title {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.02em;
}

.box-block-line {
  padding-bottom: 10px;
  border-bottom: solid 1px #e5e4e4;
}

.box-input-text {
  width: 63%;
  margin-right: 10px;
}
.box-input-text-with-filter-bill {
  width: 80%;
  margin-right: 10px;
}

.w-88 {
  width: 88%;
}

.wrapbox-list-col,
.wrapbox-table {
  position: relative;
  height: 400px;
  overflow: auto;
  margin-top: 10px;
}

.wrapbox-table {
  height: 458px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.wrapbox-list-col::-webkit-scrollbar,
.wrapbox-table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapbox-list-col,
.wrapbox-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.box-list-col {
  height: 42px;
  padding: 9px 0 9px 22px;
  border-radius: 12px;
  background-color: #f5f5f5;
  color: #3c3c3c;
  letter-spacing: -0.32px;
  font-size: 16px;
  margin-bottom: 8px;
}

.box-list-col:hover {
  background-color: rgba(255, 228, 38, 0.4);
  cursor: pointer;
}

.table-row:hover {
  background-color: rgba(255, 228, 38, 0.4);
  cursor: pointer;
}

.box-rectangle {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding: 22px 20px 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
}

.box-pagination {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: right;
}

.box-page {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.box-page div:first-child {
  flex: 1;
}

.box-wrapper-cols {
  display: flex;
}

.box-wrapper-cols > .cols {
  width: 150px;
}

.box-list-check {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 24px;
  font-size: 16px;
  color: #3c3c3c;
}

.box-list-check input {
  margin-right: 10px;
  margin-top: 0px;
}

.box-list-check input:focus {
  box-shadow: none !important;
  border: solid 1px #e0e0e0 !important;
}

.box-checkbox input {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #ced4da;
  background-color: #fff;
  cursor: pointer;
}

.box-checkbox > .form-check-input:checked[type='checkbox'] {
  background-image: url('../../../public/Images/icon-check.png') !important;
  background-position: center;
  background-size: 16px;
}

.box-checkbox > .form-check-input:checked {
  background-color: #ffe426;
  border-color: #ced4da !important;
}

.bt-gap-generation {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  letter-spacing: -0.32px;
  font-size: 14px;
  color: #3c3c3c;
  cursor: pointer;
}

.bt-submit-y {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: pointer;
}

.bt-submit-y-admin {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: default !important;
}

.bt-submit-y-unused {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid;

  border-color: #ffe426;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #ffe426;
  cursor: pointer;
}
.bt-submit-y-unused-admin {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid;
  border-color: #ffe426;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #ffe426;
  cursor: default !important;
}
.bt-submit-y-unused:hover {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;

  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: pointer;
}

.CTA_validation {
  width: 192px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  gap: 10px;
  border: none;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #43b929;
}

.center-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bt-submit-disabled-y {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(8, 6, 6, 0.05);
  background-color: #e0e0e0;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #bdbdbd;
  cursor: not-allowed !important;
}

.text-h {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #3c3c3c;
}

.box-fr {
  display: flex;
  align-items: center;
  justify-items: center;
}

.box-ui-top {
  margin-bottom: 15px;
}

.box-ui-top,
.txt-title-top {
  display: flex;
  align-items: center;
}

.box-ui-top > .txt-title-top {
  flex: 1;
}

.bt-csv {
  width: 161px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 26px;
  color: #3c3c3c;
  background-color: #f5f5f5;
  cursor: pointer;
}

.bt-plus-circle-yellow {
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url('../../../public/Images/icon-plus-hover.png');
}

.bt-csv img {
  width: 19px;
  margin-right: 8px;
}

.bt-csv:hover {
  background-color: #ffe426;
  cursor: pointer;
}

.bt-icon-msg,
.bt-icon-phone {
  display: inline-block;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  border-radius: 50%;
  border: solid 1px transparent;
}

.bt-icon-msg {
  line-height: 31px;
}

.bt-icon-phone:hover,
.bt-icon-msg:hover {
  background-color: #fff !important;
  cursor: pointer;
}

.bt-icon-trash {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-left: 5px;
  cursor: pointer;
  margin-top: -3px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}

.bt-icon-trash img {
  width: 34px;
}

.bt-icon-trash:hover {
  background: #e0e0e0;
  border-radius: 50%;
}
